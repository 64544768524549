<template>
    <tr class="w-full hover:bg-grey-lightest my-1">
        <td v-if="status === 'waiting'">
            <div class="brand-checkbox my-auto mx-auto">
                <input class="" :checked="checked" type="checkbox" :id="`ch-${event.uuid}`"/>
                <label :for="`ch-${event.uuid}`"  @click="$emit('select')"></label>
            </div>
        </td>
        <td class="mr-auto" v-text="event.city.name"></td>
        <td class="mr-auto" v-text="event.game_type"></td>
        <td class="mr-auto">{{dateRange}}</td>
        <td class="mr-auto">{{created_at}}</td>
        <td v-if="status === 'waiting'">
            <a href="" class="text-grey-darkest no-underline hover:text-grey"
               @click.prevent="setAsPaid(event.uuid)"
               >
                <svg data-v-2251fa2a="" aria-hidden="true" data-prefix="far" data-icon="check-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-6 mx-auto my-auto text-black text-base svg-inline--fa fa-check-circle fa-w-16"><path data-v-2251fa2a="" :fill=iconColor d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" class=""></path></svg>
            </a>
        </td>

    </tr>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import Trash from '../../icons/trash'
import ConfirmModal from '../../components/popperModals/confirmModal'
import format from 'date-fns/format'
import ru from 'date-fns/locale/ru'
import compareAsc from 'date-fns/compareAsc'
export default {
  components: { ConfirmModal, Trash },
  name: 'EventOrder',
  props: ['event', 'checked', 'status'],
  data () {
    return {
      data: '',
      on_loading: false,
      error_message: '',

    }
  },
  methods: {
    setAsPaid (uuid) {
      this.$emit('setAsPaid', [uuid])
    },
  },
  computed: {
    iconColor () {
      return this.checked ? 'red' : 'currentColor'
    },
    baseUrl () {
      return `/api/games/${this.game.id}/publish`
    },
    created_at () {
      return format(new Date(this.event.created_at), 'dd MMMM yyyy', { locale: ru })
    },
    dateRange () {
      const dates = []
      if (this.event.dates.length) {
        this.event.dates.forEach(date => {
          dates.push(new Date(date.played_at))
        })
      } else {
        return 'Дата игры не выбрана'
      }
      dates.sort(compareAsc)
      dates.forEach(date => {})
      if (dates.length > 1) {
        if (
          format(dates[0], 'MM', { locale: ru }) ===
          format(dates[dates.length - 1], 'MM', { locale: ru })
        ) {
          return (
            format(dates[0], 'dd', { locale: ru }) +
            ' - ' +
            format(dates[dates.length - 1], 'dd MMMM', { locale: ru })
          )
        } else {
          return (
            format(dates[0], 'dd MMMM', { locale: ru }) +
            ' - ' +
            format(dates[dates.length - 1], 'dd MMMM', { locale: ru })
          )
        }
      } else {
        return format(dates[0], 'dd MMMM', { locale: ru })
      }
    },
  },
}

</script>

<style scoped>
    @tailwind utilities;

    td {
        @apply .text-left .p-2 .bg-white .font-sans .text-sm .border-b .border-r .border-grey-light;
    }
</style>

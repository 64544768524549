<template>
    <div class="container px-4 mx-auto flex justify-between">
        <smart-filter :main-url="mainLink+sortSelect+',-uuid&'" :route="$route">
            <div class="w-full" slot-scope="{link, values}">
                <pagination :base-url="link">
                    <div class="w-full" slot-scope="{data: events, processing, switchPage, fetchData}">
                        <div class="w-full" v-if="!processing">
                            <item-count class="justify-start" :title="'игр'"></item-count>
                            <label for="sortSelect" class="font-bold mr-2">Сортировать по:</label>
                            <select v-model="sortSelect" name="sortSelect" id="sortSelect">
                              <option class="sort-option" value='-played_at'>По дате игры (в порядке убывания)</option>
                              <option class="sort-option" value="played_at">По дате игры (в порядке возрастания)</option>
                              <option class="sort-option" value="-ordered_at">По дате заказа (в порядке убывания)</option>
                              <option class="sort-option" value="ordered_at">По дате заказа (в порядке возрастания)</option>
                            </select>
                            <div class="rounded border-grey shadow-md text-left w-full overflow-x-auto mt-3">
                                <table class="w-full p-4">
                                    <thead>
                                    <tr>
                                        <th v-if="status === 'waiting'">
                                            <a href=""
                                               class="text-grey-darkest no-underline hover:text-grey mx-auto my-auto"
                                               title="Выбрать всё на этой странице"
                                               @click.prevent="selectAll(events)">
                                                <font-awesome-icon v-if="checked" class="w-6"
                                                                   :icon="['fas', 'angle-up']"></font-awesome-icon>
                                                <font-awesome-icon v-else class="w-6"
                                                                   :icon="['fas', 'angle-down']"></font-awesome-icon>
                                            </a>
                                        </th>
                                        <th class="mr-auto">Город</th>
                                        <th class="mr-auto">Категория и тематика</th>
                                        <th class="mr-auto">Диапазон дат</th>
                                        <th class="mr-auto">Дата заказа</th>
                                        <th v-if="status === 'waiting'">
                                            <a href="" class="text-grey-darkest no-underline hover:text-grey"
                                               title="Отметить как оплаченные"
                                               @click.prevent="setAsPaid(selected, fetchData)">
                                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="money-check-alt" class="svg-inline--fa fa-money-check-alt fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M608 32H32C14.33 32 0 46.33 0 64v384c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V64c0-17.67-14.33-32-32-32zM176 327.88V344c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V152c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07zM416 312c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h112c4.42 0 8 3.58 8 8v16zm160 0c0 4.42-3.58 8-8 8h-80c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16zm0-96c0 4.42-3.58 8-8 8H296c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h272c4.42 0 8 3.58 8 8v16z"></path></svg>
                                            </a>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <event-order v-for="(event, key) in events" :value="event" :key="key"
                                                 :event="event" :status="status"
                                                 :checked="selected.indexOf(event.uuid) !== -1"
                                                 @setAsPaid="setAsPaid([event.uuid], fetchData)"
                                                 @select="selectTemplate(event.uuid)"
                                                 @updated="fetchData"
                                    ></event-order>
                                    </tbody>
                                </table>
                            </div>
                            <pagination-links @page="switchPage"></pagination-links>
                        </div>
                        <spinner v-else></spinner>
                    </div>
                </pagination>
            </div>
        </smart-filter>
    </div>
</template>

<script>

import Pagination from '../../components/pagination.vue'
import PaginationLinks from '../../components/pagination-links.vue'
import Trash from '../../icons/trash'
import map from 'lodash/map'
import axios from 'axios'
import swal from 'sweetalert2'
import ItemCount from '../../components/itemCount'
import Game from './../games/game'
import Spinner from '../../components/spinner'
import SmartFilter from '../../components/questions/filter.vue'
import GameFilter from './../games/filter-panel'
import EventOrder from './EventOrder.vue'
export default {
  components: {
    GameFilter,
    Spinner,
    Game,
    ItemCount,
    Trash,
    PaginationLinks,
    Pagination,
    SmartFilter,
    EventOrder,
  },
  props: ['mainLink', 'status'],
  name: 'eventList',
  watch: {
    '$route' (to, from) {
      this.clearSelected()
    },
  },
  data () {
    return {
      selected: [],
      checked: false,
      sortSelect: '-ordered_at',
    }
  },
  methods: {
    selectAll (items) {
      let ids = map(items, 'uuid')
      if (this.selected.length === ids.length) {
        this.clearSelected()
      } else {
        this.selected = ids
        this.checked = true
      }
    },
    selectTemplate (id) {
      if (this.selected.indexOf(id) !== -1) {
        this.dropSelected(this.selected, id)
      } else {
        this.selected.push(id)
      }
    },
    clearSelected () {
      this.selected = []
      this.checked = false
    },
    dropSelected (array, id) {
      let index = array.indexOf(id)
      if (index !== -1) array.splice(index, 1)
    },
    setAsPaid (ids, fetchData) {
      if (ids.length === 0) {
        swal('Выберите записи!', 'Необходимо выбрать элементы', 'error')
        return
      }
      ids = ids.join('&event_order_ids[]=')
      let link = `api/events/orders/mark-paid?event_order_ids[]=${ids}`
      let message = 'Игры будут помечены как оплаченные'
      this.confirmPaid(message).then((result) => {
        axios.patch(link).then(() => {
          swal('Оплачено', 'Игры оплачены.', 'success')
          fetchData()
          this.$emit('paid')
          this.clearSelected()
        })
      })
    },

    confirmPaid (message) {
      const self = this
      return new Promise(function (resolve) {
        swal({
          title: 'Вы уверены?',
          text: message,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Отметить как оплаченное!',
          cancelButtonText: 'Отмена',
        }).then((result) => {
          if (result.value) {
            resolve(result)
          } else { self.clearSelected() }
        })
      })
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;
   #sortSelect {
     background-color: white
   }
    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }
</style>

<template>
    <div class="container mx-auto flex flex-col px-8 mx-4">
        <div>
            <span class="px-4">Ожидают оплаты </span>
            <event-list :status="'waiting'" @paid="updatePaid"
                        :main-link="'api/events/orders/waiting-payment?filter[status]=waiting_for_payment&sort='">
            </event-list>
        </div>
        <div class="mt-12">
            <span class="px-4">Оплаченные</span>
            <event-list :status="'paid'" :key="paidOrdersKey"
                        :main-link="'api/events/orders/waiting-payment?filter[status]=paid&sort='">
            </event-list>
        </div>
    </div>
</template>

<script>
import EventList from './events'
const uuidv4 = require('uuid/v4')
export default {
  components: { EventList },
  data () {
    return {
      paidOrdersKey: uuidv4(),
    }
  },
  methods: {
    updatePaid () {
      this.paidOrdersKey = uuidv4()
    },
  },
}
</script>

<style scoped>
    @tailwind utilities;

    th {
        @apply text-left
        p-2 bg-white font-sans text-sm border-b border-r border-grey-light;
    }
</style>
